import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
// import Seo from "../components/seo"
import Seo2 from "../components/Seo2"
// import Container from '../components/Container'
import Slider from '../components/Slider'
import HomeSliderData from '../data/homepage-slider'
// import Faq from '../components/Faq'
import FaqItems from '../components/FaqItems'
import Banner from '../components/Banner'
import Section from '../components/Section'
import Button, { ButtonGroup } from '../components/Button'
// import IconBox from '../components/IconBox'
// import Caption from '../components/Caption'
import { MediaPreFooter } from '../components/PreFooter'

const IndexPage = () => (
  <Layout>

    <Slider

      slideData={HomeSliderData}
      className={`mb-10 homepage-slider`}
    />
    <Banner className="-mt-10" />

    <Section size="medium">
      <Section.Col>
        <Section.Content
        header={`
        Welcome to Casa Canine
        `}
        bigHeader={false}
        as={`h2`}
        preheader={null}
        subheader="Only 21 minutes from Ottawa International Airport"
        content={`
          <p>
          Conveniently located close to YOW, Hwy 416, and Bank St. (Hwy 31). Easy to drop-off and pick-up your dog on your way to and from your get-away.
          </p>
          <p>
          With our online booking system and payment by e-transfer, drop-offs and pick-ups are super quick.
          </p>
          `}
        >
        <Button to={`/contact`}>Get in Touch</Button>
        </Section.Content>

      </Section.Col>
      <Section.Col hasMedia={true}>
        <StaticImage

        src={`../images/business/casak9-4297.jpg`}
        alt="Casa Canine HQ"
        />
      </Section.Col>

    </Section>


    <Section size="medium" vAlign="items-start" gapOffset="md:-mx-10">
    <Section.Col hasMedia={true} gaps="md:px-10">
      {/* <Faq style={{padding: '2rem'}} slice={-5} /> */}
        <FaqItems className={`p-2 sm:p-5 lg:p-8 md:py-5`}  slice={-5} />
    </Section.Col>
      <Section.Col gaps="md:px-10">
        <Section.Content
        header="Supervised group-play and individual attention is just part of the routine here"
        preheader="No added charges either"
        content={`
          <p>
          As dog “owners” ourselves, we know that a dog’s happiness depends on being active and interacting with other dogs. We have two huge completely fenced-in yards for supervised play time. Dogs will be outside at least 5 times each day, starting at 7am, with a late-night toilet break between 9 and 10pm.
          </p>
          <p>
          And when the energy is drained off, dogs are certain to get plenty of human affection – belly rubs galore!
          </p>
          `}
        >
        
        <ButtonGroup>
        <Button to={`/faq`}>All FAQ</Button>
        <Button to={`/contact`} variation={`secondary`}>Get in Touch</Button>
        </ButtonGroup>
        </Section.Content>

      </Section.Col>


    </Section>
    <MediaPreFooter containerSize={`flush`}>
      <ButtonGroup>
        <Button to={`/team`} variation="secondary">Meet the Team</Button>
        <Button to={`/faq`} variation="outline" invert={true} className={`sm:inline-block hidden`}>Frequently Asked Questions</Button>
      </ButtonGroup>
    </MediaPreFooter>
  </Layout>
)

export const Head = () => <Seo2 title={`Home`} />

export default IndexPage
