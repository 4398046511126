import React from 'react'
import PropTypes from 'prop-types'
import Container from './Container'
import Button from './Button'
// import { availabilityData } from '../data/availability-data'

const Banner = ({className}) => {
	return (

		<div className={`bg-primary-default text-white py-4 relative z-20 ${className}`}>
			<Container size="small">
				<div className="flex justify-center items-center -mx-5">
					<div className="px-5 font-bold font-display text-sm uppercase tracking-[0.1rem]">
					Call us at (613) 261-4140
					</div>
					<div className="px-5">
						<Button 
						type="external" variation="secondary" href="tel:6132614140" title={`Book Now`}>Book Now</Button>
					</div>
				</div>
			</Container>
		</div>
	)
}

Banner.propTypes = {
	className: PropTypes.string,
}

Banner.defaultProps = {
	className: ``,
}

export default Banner
