import * as React from 'react';
import PropTypes from 'prop-types'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Container from './Container'
import Button from './Button'
import * as sliderStyles from '../styles/slider.module.scss'
import { LoadingBlock } from '../svg/Loading';

const Slider = ( {slideData, autoPlay, className} ) => {

	// if (typeof window === "undefined") {
	//     return <p>Server Render</p>
	//  }

    let sliderClassName = 'original__slider';
    if (className) sliderClassName = ['original__slider', className].join(" ");

   const sliderOptions = {

     type: 'fade',
     rewind: true,
     autoplay: autoPlay,
     interval: 12000,
     speed: 2200,
     cover: false,
     heightRatio: 0.45,
     perPage: 1,
     perMove: 1,
     padding: '0rem',
     arrows: true,
     pagination: false,
     gap: '0rem',
     pauseOnHover: false,
     classes: {
   		// Add classes for arrows.
   		arrows: 'splide__arrows ',
   		arrow : 'splide__arrow',
   		prev  : `splide__arrow--prev arrow-left ${sliderStyles.slideArrowPrev}`,
   		next  : `splide__arrow--next arrow-right ${sliderStyles.slideArrowNext}`,
     },
     breakpoints: {
  		1024: {
  			heightRatio: 0.666667,
  		},
      786: {
  			heightRatio: 0.9,
  		},
    },
   }

   const [isLoaded, setIsLoaded] = React.useState(false);

   React.useEffect(() => {
      setIsLoaded(true);

      return () => {
        setIsLoaded(false);
      }

   }, [])

   if (!isLoaded) {
    return <LoadingBlock />
   }

	return (
		<div className="flex flex-row">

		<div className="flex-auto w-full relative">
		<Splide
          onMoved={() => console.log("Slide moved!")}
          options={sliderOptions}
		  className={sliderClassName}
        >

		{
	      slideData.map((slide, index) => {


			       return (
               <SplideSlide key={index}>

               <Container size="medium" className="relative flex lg:justify-start justify-center items-center h-full w-full z-50 lg:!px-[3rem] xl:!px-[2rem]">

               <div className="p-8 md:p-12 md:max-w-2xl bg-white rounded-sm shadow-lg">
                 <h2

                 dangerouslySetInnerHTML={{__html: slide.title}}
                 className={`font-secondary font-normal mb-3`}
                 />
                 <p
                 dangerouslySetInnerHTML={{__html: slide.content}}
                 className="tracking-wide text-sm font-medium"
                 />
                 {
                   slide.buttons &&

                   <div className="space-x-2 lg:space-x-3 xl:space-x-4">
                   {
                     slide.buttons.map((btn, key) => {
                       return <Button to={btn.link} variation={btn.variation} style={{zIndex: '50'}} key={key}>{btn.text}</Button>
                     })
                   }
                   </div>
                 }
               </div>
               </Container>
               {slide.coverImg &&
                 <div className="absolute top-0 right-0 left-0 bottom-0 w-full h-full z-0">
                  {slide.coverImg}
                 </div>
               }
        			</SplideSlide>
             )

	      })
	    }

		</Splide>
		</div>
	</div>
	)
}

Slider.propTypes = {
  slideData: PropTypes.array,
  autoPlay: PropTypes.bool,
}

Slider.defaultProps = {
  slideData: ``,
  autoPlay: true,
}

export default Slider
