import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { slideMedia } from '../styles/slider.module.scss'

const HomeSliderData = [
	{
		title: `Welcome to Casa Canine, your dog's home away from home.`,
		content: `22.5 kms to Ottawa International Airport.`,
		coverImg:
		<StaticImage
		src={`../images/stock/casak9-2803295.jpg`}
		alt="dogs"
		className={`!border-0 ${slideMedia}`}
		loading={`eager`}
		/>,
		buttons: [
			{
				text: `About Us`,
				link: `/about`,
				variation: `primary`,
			},
			{
				text: `Find Us`,
				link: `/`,
				variation: `outline`,
			},
		],
	},
	// {
	// 	title: `We're taking on new clients, so book your spot today.`,
	// 	content: `Contact Steve to introduce your dog to the pack.`,
	// 	coverImg:
	// 	<StaticImage
	// 	src={`../images/stock/casak9-2027105.jpg`}
	// 	alt="dogs"
	// 	className={`!border-0 ${slideMedia}`}
	// 	/>,
	// 	buttons: [
	// 		{
	// 			text: `Contact`,
	// 			link: `/contact`,
	// 			variation: `primary`,
	// 		},
	// 	],
	// },
	{
		title: `Offering daycare and short & long term boarding.`,
		content: `Safe, fun, friendly environment.`,
		coverImg:
		<StaticImage
		src={`../images/stock/casak9-2k6v10Y2dIg.jpg`}
		alt="dogs"
		className={`!border-0 ${slideMedia}`}
		loading={`eager`}
		/>,
		buttons: [
			{
				text: `Contact`,
				link: `/contact`,
				variation: `primary`,
			},
		],
	},
	{
		title: `A doggy paradise, fit for all kinds of dogs.`,
		content: `As dog “owners” ourselves, we know that a dog’s happiness depends on being active and interacting with other dogs.`,
		coverImg:
		<StaticImage
		src={`../images/stock/casak9-215957.jpg`}
		alt="dogs"
		className={`!border-0 ${slideMedia}`}
		/>,
		buttons: [
			{
				text: `Our Services`,
				link: `/services`,
				variation: `primary`,
			},
		],
	},
	{
		title: `Family-operated, home-based dog boarding.`,
		content: `
		<p>We have two completely-fenced yards with a total of 10 000 square feet for supervised play-time.</p>
		`,
		buttonLink: `/hoopie`,
		buttonText: 'Hoopie',
		coverImg:
		<StaticImage
		src={`../images/stock/casak9-6GhsDqjJfn8.jpg`}
		alt="dogs"
		className={`!border-0 ${slideMedia}`}
		/>,
		buttons: null,
	},
]

export default HomeSliderData
